import React, { useState } from 'react';
import HeartFilled from '../Icons/HeartFilled';
import HeartOutlined from '../Icons/HeartOutlined';
import Spinner from '../Spinner/Spinner';
import styles from './LikeButton.module.scss';

function LikeButton({ isLiked, onLike, onUnlike, likeCount }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (isLiked) {
        await onUnlike();
      } else {
        await onLike();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className={styles.likeButton}
      onClick={handleClick}
      disabled={isLoading}
      style={isLiked ? { color: '#0a0a0a' } : { color: '#0a0a0a' }}
    >
      {likeCount || 0}
      {isLoading ? (
        <Spinner size="small" />
      ) : isLiked ? (
        <HeartFilled fill="#fcd34d" />
      ) : (
        <HeartOutlined fill="#0a0a0a" />
      )}
    </button>
  );
}

export default LikeButton;
