import React from 'react';

const CommentNotification = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.49 4V4.00028L21.4994 20.9996L18.3 18.6L18.1667 18.5H18H4C3.17614 18.5 2.5 17.8239 2.5 17V4C2.5 3.17614 3.17614 2.5 4 2.5H20C20.8206 2.5 21.49 3.17287 21.49 4ZM11 15.5H11.5V15V14V13.5H11H6H5.5V14V15V15.5H6H11ZM14 11.5H14.5V11V10V9.5H14H6H5.5V10V11V11.5H6H14ZM18 7.5H18.5V7V6V5.5H18H6H5.5V6V7V7.5H6H18Z"
        fill="#FEC738"
        stroke="black"
      />
    </svg>
  );
};

export default CommentNotification;
