import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import moment from 'moment';
import { useNotifications } from '../../config/NotificationContext';
import { useUser } from '../../config/UserContext';
import styles from './NotificationsPage.module.scss';
import Check from '../Icons/Check';
import Empty from '../Icons/Empty';
import CommentLike from '../Icons/CommentLike';
import Follower from '../Icons/Follower';
import HeartFilled from '../Icons/HeartFilled';
import CommentNotification from '../Icons/CommentNotification';
// import Alert from '../Icons/Alert';

// Function to extract video ID
const extractVideoId = (url) => {
  if (!url) {
    console.warn('No URL provided to extractVideoId');
    return null;
  }
  const regex =
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

// Function to get the thumbnail URL
const getThumbnailUrl = (notification) => {
  console.log('Getting thumbnail for notification:', notification); // Debug log

  if (notification.metadata?.thumbnailURL) {
    console.log(
      'Found direct thumbnailURL:',
      notification.metadata.thumbnailURL
    );
    return notification.metadata.thumbnailURL;
  }

  const videoUrl = notification.metadata?.videoUrl || notification.URL;
  if (!videoUrl) {
    if (notification.relatedSongId) {
      console.log('Attempting to use song URL from relatedSongId');
      return `https://img.youtube.com/vi/${notification.relatedSongId}/hqdefault.jpg`;
    }
    console.warn('No URL available for thumbnail extraction');
    return './default-thumbnail.png';
  }

  const videoId = extractVideoId(videoUrl);
  if (!videoId) {
    console.warn('Could not extract video ID from URL:', videoUrl);
    return './default-thumbnail.png';
  }

  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  console.log('Generated thumbnail URL:', thumbnailUrl);
  return thumbnailUrl;
};

// Function to group notifications
const groupNotifications = (notifications) => {
  const groups = notifications.reduce((acc, notification) => {
    if (notification.type !== 'like') {
      const uniqueKey = `${notification.type}_${notification.id}`;
      acc[uniqueKey] = [notification];
      return acc;
    }

    const key = `${notification.type}_${notification.relatedSongId}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(notification);
    return acc;
  }, {});

  return Object.entries(groups).map(([key, groupedNotifs]) => {
    const sortedNotifs = groupedNotifs.sort(
      (a, b) => b.timestamp.seconds - a.timestamp.seconds
    );

    if (sortedNotifs[0].type !== 'like' || sortedNotifs.length === 1) {
      return sortedNotifs[0];
    }

    // Extract full name from content (e.g., "John Smith liked your post" -> "John Smith")
    const recentLikers = sortedNotifs.slice(0, 5).map((notification) => ({
      id: notification.senderId,
      userName: notification.content.split(' liked')[0], // Get everything before "liked"
      displayName: notification.content.split(' liked')[0], // Keep full name for display
    }));

    return {
      ...sortedNotifs[0],
      id: key,
      isGrouped: true,
      senders: recentLikers,
      totalCount: sortedNotifs.length,
      metadata: sortedNotifs[0].metadata,
      timestamp: sortedNotifs[0].timestamp,
      content: formatGroupContent(recentLikers, sortedNotifs.length),
    };
  });
};

const formatGroupContent = (recentLikers, totalLikes) => {
  const validLikers = recentLikers.filter((liker) => liker.displayName);

  if (totalLikes <= 2) {
    return `${validLikers.map((l) => l.displayName).join(' and ')} liked your post`;
  }

  const remainingCount = totalLikes - 2;
  const firstTwo = validLikers.slice(0, 2).map((l) => l.displayName);
  return `${firstTwo.join(', ')} and ${remainingCount} others liked your post`;
};

const renderAvatarGroup = (notification, userDetails) => {
  if (!notification.isGrouped) {
    // For single notifications, get full name from content
    const userName =
      notification.content.split(' liked')[0] ||
      userDetails[notification.senderId]?.userName ||
      'Unknown User';
    return (
      <div className={styles.avatarWrapper}>
        <img
          className={styles.avatar}
          src={userDetails[notification.senderId]?.avatar || '../avatar.png'}
          alt="User avatar"
        />
        <div className={styles.avatarTooltip}>{userName}</div>
      </div>
    );
  }

  // For grouped notifications
  return (
    <div className={styles.avatarGroup}>
      {notification.senders.map((sender, index) => {
        // Try different sources for the full name, prioritizing the display name
        const userName =
          sender.displayName ||
          userDetails[sender.id]?.userName ||
          sender.userName ||
          'Unknown User';

        return (
          <div key={sender.id} className={styles.avatarWrapper}>
            <img
              className={styles.groupAvatar}
              style={{
                zIndex: 5 - index,
                marginLeft: index > 0 ? '-8px' : '0',
              }}
              src={userDetails[sender.id]?.avatar || '../avatar.png'}
              alt={`${userName}'s avatar`}
            />
            <div className={styles.avatarTooltip}>{userName}</div>
          </div>
        );
      })}
    </div>
  );
};

const NotificationsPage = ({ openSongPage, handleUserPageNav }) => {
  const { getUserDetailsById } = useUser();
  const {
    notifications,
    markAllAsRead,
    markNotificationAsRead,
    fetchMoreNotifications,
    hasMore,
  } = useNotifications();

  const [userDetails, setUserDetails] = useState({});
  const observer = useRef();

  const lastNotificationElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetchMoreNotifications, hasMore]
  );

  const handleNotificationClick = async (notification) => {
    console.log('Notification clicked:', notification);

    if (!notification.isRead) {
      console.log('Marking notification as read:', notification.id);
      await markNotificationAsRead(notification.id);
    }

    switch (notification.type) {
      case 'newSong':
      case 'like':
      case 'comment':
      case 'commentLike':
      case 'replyLike':
        console.log('Navigating to song page:', notification.relatedSongId);
        openSongPage(notification.relatedSongId, notification.relatedCommentId);
        break;
      case 'follow':
        console.log('Navigating to user page:', notification.senderId);
        handleUserPageNav(notification.senderId);
        break;
      case 'reply':
        console.log(
          'Navigating to comment on song page:',
          notification.relatedSongId,
          notification.relatedCommentId
        );
        openSongPage(notification.relatedSongId, notification.relatedCommentId);
        break;
      default:
        console.log('Unknown notification type:', notification.type);
        break;
    }
  };

  const renderSongMetadata = (notification) => {
    const thumbnailUrl = getThumbnailUrl(notification);

    return (
      <div className={styles.songMetadataContainer}>
        {thumbnailUrl && (
          <div className={styles.thumbnailWrapper}>
            <img
              className={styles.songThumbnail}
              src={thumbnailUrl}
              alt={`${notification.metadata?.songTitle || 'Song'} thumbnail`}
              onError={(e) => {
                console.warn('Failed to load thumbnail:', thumbnailUrl);
                e.target.src = '../default-thumbnail.png';
              }}
            />
          </div>
        )}
        <div className={styles.songInfo}>
          {notification.metadata?.songTitle && (
            <div className={styles.songMetadata}>
              <p className={styles.songTitle}>
                {notification.metadata.songTitle}
              </p>
              <p className={styles.songArtist}>
                {notification.metadata.artistName || 'Unknown Artist'}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderNotificationContent = (notification) => {
    return (
      <div className={styles.notifBody}>
        <div className={styles.notifMain}>
          {renderAvatarGroup(notification, userDetails)}
          <div className={styles.contentWrapper}>
            <p className={styles.notifCopy}>{notification.content}</p>
          </div>
        </div>

        {/* Show comment preview for comments */}
        {(notification.type === 'comment' ||
          notification.type === 'commentLike') &&
          notification.metadata?.commentPreview && (
            <p className={styles.commentText}>
              "{notification.metadata.commentPreview}"
            </p>
          )}

        {/* Show reply preview for reply-related notifications */}
        {(notification.type === 'replyLike' || notification.type === 'reply') &&
          notification.metadata?.replyPreview && (
            <p className={`${styles.commentText} ${styles.replyText}`}>
              "{notification.metadata.replyPreview}"
            </p>
          )}

        {/* Show song metadata for all song-related notifications */}
        {(notification.type === 'like' ||
          notification.type === 'comment' ||
          notification.type === 'commentLike' ||
          notification.type === 'replyLike') &&
          notification.metadata &&
          renderSongMetadata(notification)}

        <span className={styles.notifTime}>
          {moment(notification.timestamp.toDate()).fromNow()}
        </span>
      </div>
    );
  };

  const NotificationItem = ({ notification, isLast }) => {
    const renderIcon = () => {
      switch (notification.type) {
        case 'like':
          return <HeartFilled className={styles.typeIcon} fill="#FEC738" />;
        case 'comment':
        case 'reply':
          return <CommentNotification className={styles.typeIcon} />;
        case 'commentLike':
        case 'replyLike':
          return <CommentLike className={styles.typeIcon} fill="#FEC738" />;
        case 'follow':
          return <Follower className={styles.typeIcon} fill="#FEC738" />;
        default:
          return null;
      }
    };

    return (
      <li
        ref={isLast ? lastNotificationElementRef : null}
        key={notification.id}
        className={`${styles.notifContainer} ${!notification.isRead ? styles.unread : ''}`}
        onClick={() => handleNotificationClick(notification)}
      >
        <div className={styles.typeIconContainer}>{renderIcon()}</div>
        {renderNotificationContent(notification)}
      </li>
    );
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userDetailsMap = {};
      for (const notification of notifications) {
        // For regular notifications
        const userId = notification.senderId;
        if (userId && !userDetailsMap[userId]) {
          const details = await getUserDetailsById(userId);
          userDetailsMap[userId] = details;
        }

        // For grouped notifications
        if (notification.isGrouped && notification.senders) {
          for (const sender of notification.senders) {
            if (sender.id && !userDetailsMap[sender.id]) {
              const details = await getUserDetailsById(sender.id);
              userDetailsMap[sender.id] = details;
            }
          }
        }
      }
      console.log('Fetched user details:', userDetailsMap); // Debug log
      setUserDetails(userDetailsMap);
    };

    fetchUserDetails();
  }, [notifications, getUserDetailsById]);

  useEffect(() => {
    console.log('Fetched notifications:', notifications);
    window.scrollTo(0, 0);
  }, [notifications]);

  // Group notifications before rendering
  const groupedNotifications = useMemo(() => {
    return groupNotifications(notifications);
  }, [notifications]);

  return (
    <div className={styles.notificationsPage}>
      <div className={styles.header}>
        <h3 className={styles.pageTitle}>Notifications</h3>
        <button onClick={markAllAsRead} className={styles.markAsReadButton}>
          <Check fill="#171717" />
          Mark All As Read
        </button>
      </div>
      <ul className={styles.container}>
        {groupedNotifications.length > 0 ? (
          groupedNotifications.map((notification, index) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              isLast={index === groupedNotifications.length - 1}
              isGrouped={notification.isGrouped}
            />
          ))
        ) : (
          <div className={styles.emptyState}>
            <Empty />
            <p>No notifications!</p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default NotificationsPage;
