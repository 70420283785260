import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase.config';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(FirebaseApp);
const db = getFirestore(FirebaseApp);
const functions = getFunctions(FirebaseApp, 'us-central1'); // Add explicit region

// Connect to emulator in development, use production in other environments
if (process.env.NODE_ENV === 'development') {
  console.log('[Firebase] Development environment detected');
  try {
    console.log(
      '[Firebase] Connecting to Functions emulator on localhost:5001'
    );
    connectFunctionsEmulator(functions, 'localhost', 5001);
    console.log('[Firebase] Connected to Functions emulator');
    console.log('[Firebase] Using production Firestore');
  } catch (error) {
    console.error('[Firebase] Error connecting to Functions emulator:', error);
    console.error('[Firebase] Error details:', error.message);
  }
} else {
  console.log('[Firebase] Using production environment');
}

const Providers = { google: new GoogleAuthProvider() };

export { FirebaseApp, auth, db, functions, Providers };
