import React from 'react';
import LikeButton from '../LikeButton';
import OverflowMenu from '../utils/OverflowMenu';
import styles from './SongPage.module.scss';

const DescriptionSection = ({
  songDetails,
  likesAmount,
  commentCount,
  isLiked,
  handleLike,
  handleUnlike,
  isLiking,
  isCurrentUser,
  menuOptions1,
  menuOptions2,
  setShowLikers,
  scrollToCommentForm,
  renderDescriptionWithLinks,
}) => (
  <div className={styles.descriptionSection}>
    <div className={styles.descriptionBox}>
      <span className={styles.descriptionBoxLabel}>About this song:</span>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: renderDescriptionWithLinks(songDetails.description),
        }}
      />
    </div>
    {(likesAmount > 0 || commentCount > 0) && (
      <div className={styles.metadata}>
        {likesAmount > 0 && (
          <button
            onClick={() => setShowLikers(true)}
            className={styles.metaCount}
          >
            {likesAmount} {likesAmount === 1 ? 'like' : 'likes'}
          </button>
        )}
        {commentCount > 0 && (
          <button onClick={scrollToCommentForm} className={styles.metaCount}>
            {commentCount} {commentCount === 1 ? 'comment' : 'comments'}
          </button>
        )}
      </div>
    )}
    <div className={styles.utilButtons}>
      <LikeButton
        isLiked={isLiked}
        onLike={handleLike}
        onUnlike={handleUnlike}
        likeCount={likesAmount}
        disabled={isLiking}
      />
      {isCurrentUser ? (
        <OverflowMenu options={menuOptions2} />
      ) : (
        <OverflowMenu options={menuOptions1} />
      )}
    </div>
  </div>
);

export default DescriptionSection;
