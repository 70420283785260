import React from 'react';

const Follower = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 4.5C10.9339 4.5 12.5 6.06614 12.5 8C12.5 9.93386 10.9339 11.5 9 11.5C7.06614 11.5 5.5 9.93386 5.5 8C5.5 6.06614 7.06614 4.5 9 4.5ZM16.5 19.5H1.5V18C1.5 17.5186 1.73716 17.06 2.21364 16.6202C2.69352 16.1773 3.38208 15.7882 4.18469 15.4666C5.79071 14.8233 7.7275 14.5 9 14.5C10.2725 14.5 12.2093 14.8233 13.8153 15.4666C14.6179 15.7882 15.3065 16.1773 15.7864 16.6202C16.2628 17.06 16.5 17.5186 16.5 18V19.5Z"
        fill="#FEC738"
        stroke="black"
      />
      <path d="M15 12V10H18V7H20V10H23V12H20V15H18V12H15Z" fill="black" />
    </svg>
  );
};

export default Follower;
