import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  updateDoc,
  writeBatch,
  onSnapshot,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const NotificationsContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [lastNotification, setLastNotification] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const db = getFirestore();
  const auth = getAuth();

  const fetchNotifications = useCallback(
    async (user, lastDoc = null) => {
      if (!user) return { notifications: [], lastDoc: null, hasMore: false };

      try {
        console.log('Fetching notifications for user:', user.uid);

        let notificationsQuery = query(
          collection(db, 'notifications'),
          where('recipientId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(30)
        );

        if (lastDoc) {
          notificationsQuery = query(notificationsQuery, startAfter(lastDoc));
        }

        console.log('Executing notifications query...');
        const querySnapshot = await getDocs(notificationsQuery);

        const notificationsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log(`Fetched ${notificationsList.length} notifications`);

        return {
          notifications: notificationsList,
          lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
          hasMore: querySnapshot.docs.length === 30,
        };
      } catch (error) {
        console.error('Error fetching notifications:', error);
        // Check if it's a permissions error
        if (error.code === 'permission-denied') {
          setError('Permission denied. Please try logging in again.');
        } else {
          setError('Failed to load notifications. Please try again later.');
        }
        return { notifications: [], lastDoc: null, hasMore: false };
      }
    },
    [db]
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (!user) {
        setNotifications([]);
        setLastNotification(null);
        setHasMore(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Single useEffect for handling notifications
  useEffect(() => {
    if (!currentUser) {
      setLoading(false);
      return;
    }

    console.log('Setting up notifications listener for:', currentUser.uid);
    setLoading(true);

    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('recipientId', '==', currentUser.uid),
      orderBy('timestamp', 'desc'),
      limit(30)
    );

    const unsubscribe = onSnapshot(notificationsQuery, {
      next: (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const notificationsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log(`Received ${notificationsList.length} notifications`);
        setNotifications(notificationsList);
        setLastNotification(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length === 30);
        setLoading(false);
        setError(null);
      },
      error: (error) => {
        console.error('Notifications listener error:', error);
        setError(error.message);
        setLoading(false);
      },
    });

    return () => {
      console.log('Cleaning up notifications listener');
      unsubscribe();
    };
  }, [currentUser, db]);
  const fetchMoreNotifications = useCallback(async () => {
    if (!hasMore || !lastNotification || !currentUser) return;

    const {
      notifications: newNotifications,
      lastDoc,
      hasMore: moreAvailable,
    } = await fetchNotifications(currentUser, lastNotification);

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      ...newNotifications,
    ]);
    setLastNotification(lastDoc);
    setHasMore(moreAvailable);
  }, [hasMore, lastNotification, currentUser, fetchNotifications]);

  const markAllAsRead = useCallback(async () => {
    if (!currentUser) return;
    const batch = writeBatch(db);
    notifications.forEach((notification) => {
      if (!notification.isRead) {
        const notificationRef = doc(db, 'notifications', notification.id);
        batch.update(notificationRef, { isRead: true });
      }
    });

    try {
      await batch.commit();
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          isRead: true,
        }))
      );
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      setError(error.message);
    }
  }, [currentUser, db, notifications]);

  const markNotificationAsRead = useCallback(
    async (notificationId) => {
      try {
        const notificationRef = doc(db, 'notifications', notificationId);
        await updateDoc(notificationRef, { isRead: true });

        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId
              ? { ...notification, isRead: true }
              : notification
          )
        );
      } catch (error) {
        console.error('Error marking notification as read:', error);
        setError(error.message);
      }
    },
    [db]
  );

  const hasUnreadNotifications = useMemo(() => {
    return notifications.some((notification) => !notification.isRead);
  }, [notifications]);

  const value = useMemo(
    () => ({
      notifications,
      hasUnreadNotifications,
      markAllAsRead,
      markNotificationAsRead,
      fetchMoreNotifications,
      loading,
      error,
    }),
    [
      notifications,
      hasUnreadNotifications,
      markAllAsRead,
      markNotificationAsRead,
      fetchMoreNotifications,
      loading,
      error,
    ]
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
