import React, { useState, useEffect } from 'react';
import { useSongs } from '../../config/SongContext';
import { useUser } from '../../config/UserContext';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styles from './SongListItem.module.scss';
import LikeButton from '../LikeButton';
import CommentButton from '../CommentButton';
import OverflowMenu from '../utils/OverflowMenu';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import PostFormModal from '../PostFormModal';
import { useNavigation } from '../../config/NavigationContext';

const SongListItem = ({
  song,
  onDelete,
  currentUser,
  setNavContent,
  openSongPage,
}) => {
  const { handleUserPageNav } = useNavigation();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { deleteSong, likeSong, unlikeSong } = useSongs();
  const [userDetails, setUserDetails] = useState(null);

  const handleDelete = () => {
    setShowConfirmDialog(true);
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const menuOptions1 = [
    { label: 'Watch on YouTube', handler: () => window.open(song.URL) },
  ];

  const menuOptions2 = [
    { label: 'Watch on YouTube', handler: () => window.open(song.URL) },
    { label: 'Edit', handler: handleEdit },
    { label: 'Delete', handler: handleDelete, style: { color: '#EF4444' } },
  ];

  const { getUserDetailsById } = useUser();

  const confirmDelete = async () => {
    try {
      await deleteSong(song.id);
      if (typeof onDelete === 'function') {
        onDelete(song.id);
      }
    } catch (error) {
      console.error('Error deleting song:', error);
    } finally {
      setShowConfirmDialog(false);
    }
  };

  const handleLike = async () => {
    if (!currentUser) {
      return Promise.reject(new Error('User not authenticated'));
    }

    try {
      await likeSong(song.id, currentUser.uid);
      return Promise.resolve();
    } catch (error) {
      console.error('Error liking song:', error);

      // Check for specific error types
      if (error.code === 'permission-denied') {
        throw new Error('permissions');
      } else if (error.code === 'internal') {
        throw new Error('internal');
      }

      throw error;
    }
  };

  const handleUnlike = async () => {
    if (!currentUser) {
      return Promise.reject(new Error('User not authenticated'));
    }

    try {
      await unlikeSong(song.id, currentUser.uid);
      return Promise.resolve();
    } catch (error) {
      console.error('Error unliking song:', error);

      if (error.code === 'permission-denied') {
        throw new Error('permissions');
      } else if (error.code === 'internal') {
        throw new Error('internal');
      }

      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserDetailsById(song.userId);
        setUserDetails(data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchData();
  }, [song.userId, getUserDetailsById]);

  const timestamp =
    song.timestamp &&
    'seconds' in song.timestamp &&
    'nanoseconds' in song.timestamp
      ? song.timestamp.toDate()
      : new Date();
  const formattedDate = moment(timestamp).fromNow();
  const isCurrentUser = !!currentUser && song.userId === currentUser.uid;

  const renderDescriptionWithLinks = (text) => {
    if (!text) return '';

    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text
      .replace(urlPattern, (url) => {
        const maxLength = 30; // Maximum characters to show for the URL
        const truncatedUrl =
          url.length > maxLength ? `${url.slice(0, maxLength)}...` : url;

        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${truncatedUrl}</a>`;
      })
      .replace(/\n/g, '<br>'); // Replace newline characters with <br> tags
  };

  const handleUserLinkClick = (e) => {
    e.preventDefault();
    handleUserPageNav(song.userId);
  };

  const handleSongClick = (e) => {
    e.preventDefault();
    openSongPage(song.id);
  };

  return (
    <>
      <li className={styles.container}>
        <div className={styles.body}>
          <div className={styles.head}>
            <Link
              to={`/user/${song.userId}`}
              onClick={handleUserLinkClick}
              className={styles.userLink}
            >
              <div className={styles.songPostedInfo}>
                <img
                  src={userDetails?.avatar}
                  alt={userDetails?.displayName}
                  className={styles.avatar}
                />
                <div className={styles.postedByCluster}>
                  <span className={styles.songPostedBy}>
                    {userDetails?.displayName}
                  </span>
                  <span className={styles.songPostedDate}>{formattedDate}</span>
                </div>
              </div>
            </Link>
            {isCurrentUser ? (
              <OverflowMenu options={menuOptions2} />
            ) : (
              <OverflowMenu options={menuOptions1} />
            )}
          </div>
          <div
            onClick={handleSongClick}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && handleSongClick(e)}
            className={styles.songCluster}
          >
            <div
              className={styles.thumbnailImage}
              style={{ backgroundImage: `url(${song.thumbnailURL})` }}
            />
            <div className={styles.songInfo}>
              <span className={styles.songName}>{song.songName}</span>
              <span className={styles.songArtist}>by {song.songArtist}</span>
            </div>
          </div>
          <div className={styles.songCommentBox}>
            <span className={styles.songComment}>
              <span className={styles.descriptionBoxLabel}>
                About this song:
              </span>
              <p
                className={styles.commentText}
                dangerouslySetInnerHTML={{
                  __html: renderDescriptionWithLinks(song.description),
                }}
              />
            </span>
          </div>
          <div className={styles.bottomStuff}>
            <div className={styles.utilButtons}>
              <LikeButton
                isLiked={song.likes?.includes(currentUser?.uid)}
                onLike={handleLike}
                onUnlike={handleUnlike}
                likeCount={song.likes ? song.likes.length : 0}
              />
              <CommentButton
                songId={song.id}
                commentCount={song.comments ? song.comments.length : 0}
                openSongPage={openSongPage}
              />
            </div>
          </div>
        </div>
      </li>

      {showConfirmDialog && (
        <ConfirmDeleteDialog
          message="Are you sure you want to delete this song?"
          show={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          confirm={confirmDelete}
        />
      )}

      {showEditModal && (
        <PostFormModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          isEditing={true}
          postToEdit={song}
        />
      )}
    </>
  );
};

export default SongListItem;
