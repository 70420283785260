import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useRef,
  memo,
  useMemo,
} from 'react';
import { useSongs } from '../../config/SongContext';
import { useUser } from '../../config/UserContext';
import { Helmet } from 'react-helmet';
import { useNavigation } from '../../config/NavigationContext';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import Logo from '../Icons/logo';
import NavBar from './NavBar';
import SongInfo from './SongInfo';
import PlayerSection from './PlayerSection';
import DescriptionSection from './DescriptionSection';
import CommentForm from '../CommentForm/CommentForm';
import CommentSection from './CommentSection';
import LikersModal from './LikersModal';
import EditModal from './EditModal';
import Spinner from '../Spinner/Spinner';
import styles from './SongPage.module.scss';

const SongPage = memo(
  forwardRef(({ song, onClose, openSongPage }, ref) => {
    const { handleUserPageNav } = useNavigation();
    const [isLoading, setIsLoading] = useState(true);
    const [songDetails, setSongDetails] = useState(song);
    const [userDetails, setUserDetails] = useState(null);
    const [isLiking, setIsLiking] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showLikers, setShowLikers] = useState(false);
    const [likersDetails, setLikersDetails] = useState([]);
    const [navbarState, setNavbarState] = useState('initial');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const isTransitioning = useRef(false);
    const mountedRef = useRef(false);
    const isInitializedRef = useRef(false);
    const playerRef = useRef(null);
    const commentFormRef = useRef(null);
    const songInfoRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const functions = getFunctions();
    const db = getFirestore();
    const { getNextSong, setCurrentSong, deleteSong } = useSongs();

    const {
      getSongById,
      likeSong,
      unlikeSong,
      addComment,
      deleteComment,
      addReply,
    } = useSongs();
    const { currentUser, getUserDetailsById } = useUser();

    useEffect(() => {
      mountedRef.current = true;

      return () => {
        mountedRef.current = false;
        if (playerRef.current?.destroy) {
          playerRef.current.destroy();
        }
      };
    }, [song.id]);

    // Update song details when song changes
    useEffect(() => {
      if (song.id !== songDetails?.id) {
        setSongDetails(song);
        isTransitioning.current = false;
      }
    }, [song.id, song]);

    useEffect(() => {
      if (!isInitializedRef.current) {
        isInitializedRef.current = true;

        return;
      }

      return () => {
        console.log('SongPage cleanup');
        if (playerRef.current && playerRef.current.destroy) {
          playerRef.current.destroy();
        }
      };
    }, []);

    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth < 768);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleUserPageNavFromSong = useCallback(
      (userId) => {
        if (isMobile) {
          onClose();
        }
        handleUserPageNav(userId);
      },
      [isMobile, onClose, handleUserPageNav]
    );

    //SCROLL LISTENER
    useEffect(() => {
      const handleScroll = () => {
        const currentScroll = scrollContainerRef.current?.scrollTop || 0;
        setScrollPosition(currentScroll);

        const scrollThreshold = 100;
        if (currentScroll > scrollThreshold) {
          setNavbarState('scrolled');
        } else {
          setNavbarState('initial');
        }
      };

      const container = scrollContainerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll, { passive: true });
      }

      // Reset scroll position when song changes
      if (container) {
        container.scrollTop = 0;
        setScrollPosition(0);
        setNavbarState('initial');
      }

      return () => {
        if (container) {
          container.removeEventListener('scroll', handleScroll);
        }
      };
    }, [song.id, scrollContainerRef.current]); // Add ref.current as dependency

    const scrollToCommentForm = () => {
      if (commentFormRef.current) {
        commentFormRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        });
      }
    };

    const isLiked = useMemo(
      () => songDetails?.likes?.includes(currentUser?.uid) || false,
      [songDetails, currentUser]
    );

    const commentCount = useMemo(
      () => songDetails?.comments?.length || 0,
      [songDetails]
    );

    useEffect(() => {
      const fetchSongDetails = async () => {
        if (song.id) {
          const songData = await getSongById(song.id);
          setSongDetails(songData);
        }
      };

      fetchSongDetails();
    }, [song.id, getSongById]);

    useEffect(() => {
      if (songDetails && songDetails.userId) {
        const fetchUserDetails = async () => {
          const data = await getUserDetailsById(songDetails.userId);
          setUserDetails(data);
        };
        fetchUserDetails();
      }
    }, [songDetails, getUserDetailsById]);

    const handleLike = useCallback(async () => {
      if (currentUser && currentUser.uid && !isLiking) {
        setIsLiking(true);
        try {
          await likeSong(songDetails.id, currentUser.uid);
          // Update local state directly instead of fetching
          setSongDetails((prev) => ({
            ...prev,
            likes: [...(prev.likes || []), currentUser.uid],
          }));
        } catch (error) {
          console.error('Error liking song:', error);
        } finally {
          setIsLiking(false);
        }
      }
    }, [currentUser, likeSong, songDetails, isLiking]);

    const handleUnlike = useCallback(async () => {
      if (currentUser && currentUser.uid && !isLiking) {
        setIsLiking(true);
        try {
          await unlikeSong(songDetails.id, currentUser.uid);
          // Update local state directly instead of fetching
          setSongDetails((prev) => ({
            ...prev,
            likes: (prev.likes || []).filter((id) => id !== currentUser.uid),
          }));
        } catch (error) {
          console.error('Error unliking song:', error);
        } finally {
          setIsLiking(false);
        }
      }
    }, [currentUser, unlikeSong, songDetails, isLiking]);

    const handleAddComment = useCallback(
      async (songId, newComment) => {
        if (currentUser && newComment && newComment.text.trim() !== '') {
          await addComment(songId, newComment);
          const updatedSong = await getSongById(songId);
          setSongDetails(updatedSong);

          const commentNotificationFunction = httpsCallable(
            functions,
            'commentNotification'
          );
          try {
            await commentNotificationFunction({
              songId,
              commentText: newComment.text,
            });
          } catch (error) {
            console.error('Error sending comment notification:', error);
          }
        }
      },
      [currentUser, addComment, getSongById, functions]
    );

    const handleDeleteComment = useCallback(
      async (commentId) => {
        try {
          console.log('Attempting to delete comment with ID:', commentId);
          await deleteComment(songDetails.id, commentId);
          const updatedSong = await getSongById(songDetails.id);
          setSongDetails(updatedSong);
          console.log('Comment deleted successfully:', commentId);
        } catch (error) {
          console.error('Error deleting comment:', error);
        }
      },
      [deleteComment, songDetails.id, getSongById]
    );

    const handleDeleteReply = useCallback(
      async (commentId, replyIndex) => {
        const songRef = doc(db, 'songs', songDetails.id);
        const updatedComments = songDetails.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.filter(
              (_, index) => index !== replyIndex
            );
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });
        await updateDoc(songRef, { comments: updatedComments });
        setSongDetails((prevDetails) => ({
          ...prevDetails,
          comments: updatedComments,
        }));
      },
      [songDetails.id, songDetails.comments, db]
    );

    const handleDelete = () => {
      setShowConfirmDialog(true);
    };

    const confirmDelete = async () => {
      try {
        await deleteSong(songDetails.id);
        console.log(`Song with ID ${songDetails.id} deleted successfully.`);
        setShowConfirmDialog(false);
        onClose(); // Close the SongPage after deletion
      } catch (error) {
        console.error('Error deleting song:', error);
      }
    };

    useEffect(() => {
      if (songDetails && songDetails.likes) {
        const fetchLikersDetails = async () => {
          const userDetailsList = [];
          for (const userId of songDetails.likes) {
            const data = await getUserDetailsById(userId);
            userDetailsList.push(data);
          }
          setLikersDetails(userDetailsList);
        };

        fetchLikersDetails();
      }
    }, [songDetails, getUserDetailsById]);

    const likesAmount = songDetails?.likes?.length || 0;
    const isCurrentUser =
      songDetails && currentUser && songDetails.userId === currentUser.uid;

    const renderDescriptionWithLinks = (text) => {
      if (!text) return '';
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlPattern, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    };

    const menuOptions1 = [
      {
        label: 'Watch on YouTube',
        handler: () => window.open(songDetails.URL),
      },
    ];
    const menuOptions2 = [
      {
        label: 'Watch on YouTube',
        handler: () => window.open(songDetails.URL),
      },
      {
        label: 'Edit',
        handler: () => setIsEditModalOpen(true),
      },
      {
        label: 'Delete',
        handler: handleDelete,
        style: { color: '#EF4444' },
      },
    ];

    const handleAddReply = useCallback(
      async (songId, commentId, newReply) => {
        if (currentUser && newReply && newReply.text.trim() !== '') {
          try {
            await addReply(songId, commentId, {
              userId: currentUser.uid,
              displayName: currentUser.displayName,
              text: newReply.text,
            });

            const updatedSong = await getSongById(songId);
            setSongDetails(updatedSong);

            const replyNotificationFunction = httpsCallable(
              functions,
              'replyNotification'
            );

            const result = await replyNotificationFunction({
              songId,
              commentId,
              replyText: newReply.text,
            });
          } catch (error) {
            console.error('Error in handleAddReply:', error);
            if (error.code === 'functions/invalid-argument') {
              console.error('Invalid argument:', error.details);
            } else if (error.code === 'functions/internal') {
              console.error('Internal error:', error.details);
            } else {
              console.error('Unexpected error:', error);
            }
          }
        } else {
          console.error('Invalid reply attempt:', {
            currentUser: !!currentUser,
            newReply: !!newReply,
            replyText: newReply ? newReply.text : null,
          });
        }
      },
      [currentUser, addReply, getSongById, functions]
    );

    const handleSongEnd = useCallback(async () => {
      console.log('[SongPage] Handling song end for:', song.id);

      try {
        // Get the next song
        const nextSong = await getNextSong(song.id);
        console.log('[SongPage] Next song found:', nextSong?.id);

        if (nextSong) {
          // First update the context
          setCurrentSong(nextSong);
          // Then trigger the page update
          setTimeout(() => {
            console.log('[SongPage] Triggering navigation to next song');
            openSongPage(nextSong.id);
          }, 0);
        } else {
          console.log('[SongPage] No next song available');
        }
      } catch (error) {
        console.error('[SongPage] Error during song transition:', error);
      }
    }, [song.id, getNextSong, setCurrentSong, openSongPage]);

    useEffect(() => {
      const loadData = async () => {
        if (!song.id) return;

        setIsLoading(true);
        try {
          if (song.id !== songDetails?.id) {
            setSongDetails(song); // Set initial data immediately

            // Load user details if needed
            if (
              song.userId &&
              (!userDetails || userDetails.uid !== song.userId)
            ) {
              const userData = await getUserDetailsById(song.userId);
              setUserDetails(userData);
            }
          }
        } catch (error) {
          console.error('[SongPage] Error loading data:', error);
        } finally {
          setIsLoading(false);
        }
      };

      loadData();
    }, [song.id, song.userId, getUserDetailsById]);

    // Loading UI
    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner} />
          <div className={styles.loadingText}>Loading song...</div>
        </div>
      );
    }

    return (
      <div className={styles.songPage}>
        {isTransitioning.current && (
          <div className={styles.loadingOverlay}>
            <Spinner size="large" />
            <div className={styles.loadingText}>Loading...</div>
          </div>
        )}
        <Helmet>
          <title>{`${songDetails?.songName} by ${songDetails?.songArtist} | Good Song Club`}</title>
          <meta
            property="og:title"
            content={`${songDetails?.songName} by ${songDetails?.songArtist} | Good Song Club`}
          />
          <meta property="og:description" content={songDetails?.description} />
          <meta
            property="og:image"
            content={
              songDetails?.thumbnailURL
                ? songDetails.thumbnailURL
                : '/logo192.png'
            }
          />
          <meta
            property="twitter:image"
            content={
              songDetails?.thumbnailURL
                ? songDetails.thumbnailURL
                : '/logo192.png'
            }
          />
          <meta property="twitter:card" content="summary" />
          <meta
            property="twitter:title"
            content={`${songDetails?.songName} by ${songDetails?.songArtist} | Good Song Club`}
          />
          <meta
            property="twitter:description"
            content={songDetails?.description}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="twitter:url" content={window.location.href} />
          <meta
            property="og:image:alt"
            content={`Thumbnail for ${songDetails?.songName} by ${songDetails?.songArtist}`}
          />
          <meta property="og:type" content="website" />
        </Helmet>

        {songDetails ? (
          <div className={styles.songPageContainer}>
            <NavBar
              songDetails={songDetails}
              navbarState={navbarState}
              onClose={onClose}
              onSkip={handleSongEnd}
            />
            <div ref={scrollContainerRef} className={styles.postBodyContainer}>
              <div className={styles.postBody}>
                <SongInfo
                  songDetails={songDetails}
                  userDetails={userDetails}
                  handleUserPageNavFromSong={handleUserPageNavFromSong}
                  songInfoRef={songInfoRef}
                />

                <div className={styles.container}>
                  <PlayerSection
                    songDetails={songDetails}
                    handleSongEnd={handleSongEnd}
                    playerRef={playerRef}
                  />

                  <DescriptionSection
                    songDetails={songDetails}
                    likesAmount={likesAmount}
                    commentCount={commentCount}
                    isLiked={isLiked}
                    handleLike={handleLike}
                    handleUnlike={handleUnlike}
                    isLiking={isLiking}
                    isCurrentUser={isCurrentUser}
                    menuOptions1={menuOptions1}
                    menuOptions2={menuOptions2}
                    setShowLikers={setShowLikers}
                    scrollToCommentForm={scrollToCommentForm}
                    renderDescriptionWithLinks={renderDescriptionWithLinks}
                  />
                  {currentUser && (
                    <CommentForm
                      ref={commentFormRef}
                      currentUser={currentUser}
                      addComment={handleAddComment}
                      songId={songDetails.id}
                      placeholder="Leave a comment"
                    />
                  )}
                </div>
                <CommentSection
                  songDetails={songDetails}
                  currentUser={currentUser}
                  handleAddComment={handleAddComment}
                  handleDeleteComment={handleDeleteComment}
                  handleDeleteReply={handleDeleteReply}
                  handleAddReply={handleAddReply}
                  showConfirmDialog={showConfirmDialog}
                  confirmDelete={confirmDelete}
                  setShowConfirmDialog={setShowConfirmDialog}
                  commentFormRef={commentFormRef}
                  handleUserPageNavFromSong={handleUserPageNavFromSong}
                />
                <div className={styles.footer}>
                  <Logo className={styles.logo} />
                </div>
              </div>
            </div>
            <LikersModal
              showLikers={showLikers}
              setShowLikers={setShowLikers}
              likersDetails={likersDetails}
              handleUserPageNavFromSong={handleUserPageNavFromSong}
            />
            <EditModal
              isEditModalOpen={isEditModalOpen}
              setIsEditModalOpen={setIsEditModalOpen}
              songDetails={songDetails}
            />
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  }),
  (prevProps, nextProps) => {
    // Only re-render if essential props change
    return (
      prevProps.song.id === nextProps.song.id &&
      prevProps.onClose === nextProps.onClose &&
      prevProps.openSongPage === nextProps.openSongPage
    );
  }
);

export default SongPage;
