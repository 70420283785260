import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  getFirestore,
  query,
  collection,
  orderBy,
  limit,
  startAfter,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  arrayUnion,
  arrayRemove,
  Timestamp,
  onSnapshot,
  updateDoc,
  where,
  deleteDoc,
  getCountFromServer,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const db = getFirestore();
const functions = getFunctions();
const SongsContext = createContext();

export const useSongs = () => {
  return useContext(SongsContext);
};

export const SongsProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);
  const [totalSongCount, setTotalSongCount] = useState(0);
  const [currentSong, setCurrentSong] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMoreSongs, setHasMoreSongs] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState(true);
  const songsRef = useRef([]);
  const pageSize = 50;
  const [lastFetchedDay, setLastFetchedDay] = useState(null);

  useEffect(() => {
    songsRef.current = songs;
  }, [songs]);

  useEffect(() => {
    const getTotalSongCount = async () => {
      try {
        const snapshot = await getCountFromServer(collection(db, 'songs'));
        setTotalSongCount(snapshot.data().count);
      } catch (error) {
        console.error('Error getting total song count:', error);
      }
    };

    getTotalSongCount();

    console.log('[SongsContext] Setting up song listener');
    const songCollection = collection(db, 'songs');

    // Create query for initial fetch
    const initialQuery = query(
      songCollection,
      orderBy('timestamp', 'desc'),
      limit(pageSize)
    );

    const unsubscribe = onSnapshot(
      initialQuery,
      (snapshot) => {
        // Ignore local writes to prevent duplicate updates
        if (snapshot.metadata.hasPendingWrites) {
          console.log('[SongsContext] Ignoring local write');
          return;
        }

        console.log('[SongsContext] Received song update');
        const songList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSongs(songList);
        songsRef.current = songList;
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMoreSongs(songList.length === pageSize);
      },
      (error) => {
        console.error('[SongsContext] Error in song listener:', error);
      }
    );

    return () => {
      console.log('[SongsContext] Cleaning up song listener');
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchInitialSongs = async () => {
      try {
        const today = new Date();
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(today.getDate() - 2);

        const songCollection = collection(db, 'songs');
        const initialQuery = query(
          songCollection,
          where('timestamp', '<=', Timestamp.fromDate(today)),
          where('timestamp', '>=', Timestamp.fromDate(twoDaysAgo)),
          orderBy('timestamp', 'desc')
        );

        const snapshot = await getDocs(initialQuery);
        const songList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSongs(songList);
        songsRef.current = songList;
        setLastFetchedDay(twoDaysAgo);
      } catch (error) {
        console.error('Error fetching initial songs:', error);
      }
    };

    fetchInitialSongs();
  }, []);

  const loadMoreSongs = useCallback(async () => {
    if (loadingMore || !lastFetchedDay) {
      return;
    }

    setLoadingMore(true);
    try {
      const nextDay = new Date(lastFetchedDay);
      nextDay.setDate(lastFetchedDay.getDate() - 1);

      const songCollection = collection(db, 'songs');
      const nextQuery = query(
        songCollection,
        where('timestamp', '<', Timestamp.fromDate(lastFetchedDay)),
        where('timestamp', '>=', Timestamp.fromDate(nextDay)),
        orderBy('timestamp', 'desc')
      );

      const snapshot = await getDocs(nextQuery);
      const newSongs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setSongs((prevSongs) => [...prevSongs, ...newSongs]);
      songsRef.current = [...songsRef.current, ...newSongs];
      setLastFetchedDay(nextDay);
    } catch (error) {
      console.error('Error loading more songs:', error);
    } finally {
      setLoadingMore(false);
    }
  }, [loadingMore, lastFetchedDay]);

  const deleteSong = async (songId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      await deleteDoc(songRef);
      setSongs((prevSongs) => prevSongs.filter((song) => song.id !== songId));
      console.log(`Song with ID ${songId} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting song:', error);
    }
  };

  const getNextSong = useCallback(
    async (currentSongId) => {
      console.log('[SongsContext] Fetching a random song from the backend');

      if (!currentSongId) {
        console.error('[SongsContext] Invalid currentSongId provided');
        return await getFallbackSong(currentSongId);
      }

      try {
        const getRandomSongFn = httpsCallable(functions, 'getRandomSong');
        console.log('[SongsContext] Making function call');

        const result = await getRandomSongFn({
          currentId: currentSongId,
          environment: process.env.NODE_ENV,
        });

        console.log('[SongsContext] Got response:', result);

        const randomSong = result.data;
        if (
          randomSong &&
          randomSong.id &&
          randomSong.URL &&
          randomSong.songName &&
          randomSong.songArtist &&
          randomSong.id !== currentSongId
        ) {
          console.log('[SongsContext] Random song selected:', randomSong.id);
          return randomSong;
        }

        console.log('[SongsContext] Invalid song data, using fallback');
        return await getFallbackSong(currentSongId);
      } catch (error) {
        console.error('[SongsContext] Error in getNextSong:', error);
        return await getFallbackSong(currentSongId);
      }
    },
    [functions]
  );

  // Helper function to get a fallback song from the local array
  const getFallbackSong = async (currentSongId) => {
    // Filter out the current song and any invalid songs
    const availableSongs = songsRef.current.filter(
      (song) =>
        song.id !== currentSongId &&
        song.URL &&
        song.songName &&
        song.songArtist
    );

    if (availableSongs.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableSongs.length);
      const fallbackSong = availableSongs[randomIndex];
      console.log('[SongsContext] Fallback song selected:', fallbackSong.id);
      return fallbackSong;
    }

    // If no valid songs in current array, try to fetch more
    if (loadMoreSongs && !loadingMore) {
      console.log('[SongsContext] Attempting to load more songs for fallback');
      await loadMoreSongs();
      return getFallbackSong(currentSongId); // Try again with new songs
    }

    console.log('[SongsContext] No valid fallback songs available');
    return null;
  };

  const getSongById = async (songId) => {
    try {
      const songDoc = await getDoc(doc(db, 'songs', songId));
      if (songDoc.exists()) {
        return { id: songDoc.id, ...songDoc.data() };
      } else {
        console.error('No such song!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching song:', error);
      return null;
    }
  };

  const likeSong = async (songId, userId) => {
    console.log(`Liking song: ${songId} by user: ${userId}`);
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);

    try {
      const songSnapshot = await getDoc(songRef);
      if (!songSnapshot.exists()) {
        throw new Error('Song not found');
      }
      const songData = songSnapshot.data();

      const userSnapshot = await getDoc(userRef);
      if (!userSnapshot.exists()) {
        throw new Error('User not found');
      }
      const userData = userSnapshot.data();

      const batch = writeBatch(db);
      batch.update(songRef, {
        likes: arrayUnion(userId),
      });

      const likedAt = Timestamp.now();
      batch.update(userRef, {
        likedSongs: arrayUnion({ songId, likedAt }),
      });

      await batch.commit();

      try {
        const likeSongNotification = httpsCallable(
          functions,
          'likeSongNotification'
        );
        console.log('Calling likeSongNotification with data:', {
          songId,
          songData: {
            URL: songData.URL,
            songName: songData.songName,
            songArtist: songData.songArtist,
            thumbnailURL: songData.thumbnailURL,
          },
          userData: {
            userName: userData.userName,
          },
        });

        const result = await likeSongNotification({
          songId,
          songData: {
            URL: songData.URL,
            songName: songData.songName,
            songArtist: songData.songArtist,
            thumbnailURL: songData.thumbnailURL,
          },
          userData: {
            userName: userData.userName,
          },
        });

        console.log('Notification result:', result);
      } catch (notificationError) {
        console.error('Failed to send notification:', notificationError);
      }

      setSongs((prevSongs) =>
        prevSongs.map((song) =>
          song.id === songId
            ? { ...song, likes: [...(song.likes || []), userId] }
            : song
        )
      );

      console.log(`Song liked successfully: ${songId}`);
      return true;
    } catch (error) {
      console.error('Error liking the song:', error);
      throw error;
    }
  };

  const unlikeSong = async (songId, userId) => {
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);

    try {
      const batch = writeBatch(db);

      batch.update(songRef, {
        likes: arrayRemove(userId),
      });

      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      const likedSongObject = userData.likedSongs.find(
        (obj) => obj.songId === songId
      );

      if (likedSongObject) {
        batch.update(userRef, {
          likedSongs: arrayRemove(likedSongObject),
        });
      }

      await batch.commit();

      setSongs((prevSongs) =>
        prevSongs.map((song) =>
          song.id === songId
            ? {
                ...song,
                likes: (song.likes || []).filter((id) => id !== userId),
              }
            : song
        )
      );
    } catch (error) {
      console.error('Error unliking the song:', error);
    }
  };

  // In SongContext.js, update these two functions:

  const addComment = async (songId, newComment) => {
    const songRef = doc(db, 'songs', songId);
    if (
      !songId ||
      !newComment.userId ||
      !newComment.displayName ||
      !newComment.text
    ) {
      console.error('Error: One or more required fields are undefined.');
      return;
    }

    const commentToAdd = {
      id: Date.now().toString(),
      userId: newComment.userId,
      userName: newComment.displayName,
      commentText: newComment.text,
      timestamp: Timestamp.now(),
      likes: [],
      replies: [],
    };

    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();
      if (songData) {
        let updatedComments = [...(songData.comments || []), commentToAdd];
        await updateDoc(songRef, { comments: updatedComments });
        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const addReply = async (songId, commentId, newReply) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();
      if (songData?.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const reply = {
              id: Date.now().toString(),
              userId: newReply.userId,
              userName: newReply.displayName,
              commentText: newReply.text,
              timestamp: Timestamp.now(),
              likes: [],
            };
            return {
              ...comment,
              replies: [...(comment.replies || []), reply],
            };
          }
          return comment;
        });
        await updateDoc(songRef, { comments: updatedComments });
        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );
      }
    } catch (error) {
      console.error('Error in addReply:', error);
      throw error;
    }
  };

  const fetchSongsByUser = async (userId) => {
    try {
      const songCollection = collection(db, 'songs');
      const userSongsQuery = query(
        songCollection,
        where('userId', '==', userId)
      );

      const snapshot = await getDocs(userSongsQuery);
      const songs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log('Fetched user songs:', songs.length);
      return songs;
    } catch (error) {
      console.error('Error fetching user songs:', error);
      return [];
    }
  };

  const deleteComment = async (songId, commentId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.filter(
          (comment) => comment.id !== commentId
        );

        await updateDoc(songRef, { comments: updatedComments });

        console.log(`Comment with ID ${commentId} deleted successfully.`);
      } else {
        console.error('Song or comments not found');
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const deleteReply = async (songId, commentId, replyIndex) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.filter(
              (_, index) => index !== replyIndex
            );
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        console.log(`Reply at index ${replyIndex} deleted successfully.`);
      } else {
        console.error('Song or comments not found');
      }
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  // LIKE COMMENT LOGIC
  const likeComment = async (songId, commentId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              likes: [...(comment.likes || []), userId],
            };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );

        const likeCommentNotification = httpsCallable(
          functions,
          'likeCommentNotification'
        );
        await likeCommentNotification({
          songId,
          commentId,
          isReply: false,
        });

        console.log(`Comment with ID ${commentId} liked successfully.`);
      }
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  // LIKE REPLY LOGIC
  const likeReply = async (songId, commentId, replyId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData?.comments) {
        const comment = songData.comments.find((c) => c.id === commentId);
        if (!comment) throw new Error('Comment not found');

        const replyIndex = comment.replies.findIndex((r) => r.id === replyId);
        if (replyIndex === -1) throw new Error('Reply not found');

        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.map((reply) => {
              if (reply.id === replyId) {
                return {
                  ...reply,
                  likes: [...(reply.likes || []), userId],
                };
              }
              return reply;
            });
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );

        const likeCommentNotification = httpsCallable(
          functions,
          'likeCommentNotification'
        );
        await likeCommentNotification({
          songId,
          commentId,
          isReply: true,
          replyIndex,
        });
      }
    } catch (error) {
      console.error('Error liking reply:', error);
    }
  };

  const unlikeComment = async (songId, commentId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              likes: (comment.likes || []).filter((id) => id !== userId),
            };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );

        console.log(`Comment with ID ${commentId} unliked successfully.`);
      }
    } catch (error) {
      console.error('Error unliking comment:', error);
    }
  };

  const unlikeReply = async (songId, commentId, replyId, userId) => {
    try {
      const songRef = doc(db, 'songs', songId);
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData?.comments) {
        const updatedComments = songData.comments.map((comment) => {
          if (comment.id === commentId) {
            const updatedReplies = comment.replies.map((reply) => {
              if (reply.id === replyId) {
                return {
                  ...reply,
                  likes: (reply.likes || []).filter((id) => id !== userId),
                };
              }
              return reply;
            });
            return { ...comment, replies: updatedReplies };
          }
          return comment;
        });

        await updateDoc(songRef, { comments: updatedComments });

        setSongs((prevSongs) =>
          prevSongs.map((song) =>
            song.id === songId ? { ...song, comments: updatedComments } : song
          )
        );
      }
    } catch (error) {
      console.error('Error unliking reply:', error);
    }
  };

  const value = {
    songs,
    totalSongCount,
    currentSong,
    setCurrentSong,
    setSongs,
    loadMoreSongs,
    deleteSong,
    getNextSong,
    getSongById,
    likeSong,
    unlikeSong,
    addComment,
    addReply,
    fetchSongsByUser,
    where,
    deleteComment,
    deleteReply,
    likeComment,
    unlikeComment,
    likeReply,
    unlikeReply,
  };

  return (
    <SongsContext.Provider value={value}>{children}</SongsContext.Provider>
  );
};

export default SongsProvider;
